import React from "react";
import { Loader, Tooltip, Whisper,Button , Notification, Table, Panel } from "rsuite";
import axios from "../../../utilities/axios";
import _ from "lodash";
import {CustomModal} from "../../customModal/CustomModal"
import TablePagination from "rsuite/lib/Table/TablePagination";


const { Column, HeaderCell, Cell } = Table;

export class TableComplement extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        propsPsm: null,
        dataColumns: [],
        page: 1,
        displayLength: 10,
    }

    componentDidMount = async () =>{
        await axios
            .get(`api/v1/dev/list-table/development_create_tables`)
            .then((res) => {
                if(res.data.status == 200 ){
                    this.setState({
                        data: res.data.data
                    })
                }
            })
    }

    handleChangePage(dataKey:any) {
        console.log("Data", dataKey)
        this.setState({
          page: dataKey
        });
    }
    handleChangeLength(dataKey:any) {
        this.setState({
            page: 1,
            displayLength: dataKey
        });
    }

    public select: any = {};

    onChange = (item: any, valuesOmie: any) => {

    };

    isExecuteQuery = () => {
        let props = this.props.values;

    }
    
    isRenderBody = (data:any) => {
        this.setState({
            show:true
        });
        let jsonData = JSON.parse(data.development_create_table_columns)
        this.setState({
            dataColumns: jsonData
        })
    }

    render() {
        return (<>
            <CustomModal
                show={this.state.show}
                tableOmie={false}
                onCancel={() => {
                    this.setState({
                        show: false,
                    })
                }}
                widthNumber={700}
                showConfirm={false}
                title={"Colunas Adicionadas"}
                body={<>
                    <Panel bordered bodyFill style={{ padding: 8, width: '100%' }}>
                        <Table wordWrap loading={this.props.loading} height={400} data={this.state.dataColumns}>
                            <Column flexGrow={3} >
                                <HeaderCell>Mensagem:</HeaderCell>
                                <Cell dataKey="msgInsert" />
                            </Column>
                            <Column flexGrow={2} resizable>
                                <HeaderCell>Coluna:</HeaderCell>
                                <Cell dataKey="columnAdd" />
                            </Column>
                            <Column flexGrow={2} resizable>
                                <HeaderCell>Tipo</HeaderCell>
                                <Cell dataKey="type" />
                            </Column>
                        </Table>
                        <TablePagination
                            lengthMenu={[
                                {
                                value: 10,
                                label: 10
                                },
                                {
                                value: 20,
                                label: 20
                                }
                            ]}
                            activePage={this.state.page}
                            displayLength={this.state.displayLength}
                            total={this.state.dataColumns.length}
                            onChangePage={this.handleChangePage}
                            onChangeLength={this.handleChangeLength}
                        />
                    </Panel>
                </>}
                loading={this.state.loading}
            
            />
            <Panel bordered bodyFill style={{ padding: 8, width: '100%' }}>
                <Table wordWrap loading={this.props.loading} height={400} data={this.state.data}>
                    <Column flexGrow={2} >
                        <HeaderCell>Ação:</HeaderCell>
                        <Cell dataKey="development_create_table_name" />
                    </Column>
                    <Column flexGrow={2} resizable>
                        <HeaderCell>Criado em:</HeaderCell>
                        <Cell dataKey="development_create_table_created_at" />
                    </Column>
                    <Column flexGrow={2} resizable>
                        <HeaderCell>Impresso em:</HeaderCell>
                        <Cell dataKey="development_create_table_print_date" />
                    </Column>
                    <Column fixed="right" width={100}>
                        <HeaderCell>Ações</HeaderCell>
                        <Cell className="link-group">
                            {(rowData: any) => {
                                //return this.openModal(rowData);                               
                                return (
                                <span>
                                    <Button onClick={()=> this.isRenderBody(rowData)} > Visualizar </Button>
                                </span>
                                );
                            }}
                        </Cell>
                    </Column>
                </Table>
                {/* <TablePagination
                lengthMenu={[
                    {
                    value: 10,
                    label: 10
                    },
                    {
                    value: 20,
                    label: 20
                    }
                ]}
                activePage={this.state.page}
                displayLength={this.state.displayLength}
                total={this.state.data.length}
                onChangePage={(value:any) => this.handleChangePage(value)}
                onChangeLength={this.handleChangeLength}
                /> */}
            </Panel>
        </>)
    }
}
