import React from "react";
import { ErrorMessage, Input, InputGroup } from "rsuite";
import * as mask from "inputmask";
import classNames from "classnames";
import { FormControlBaseProps, StandardProps } from "rsuite/lib/@types/common";
import dayjs from "dayjs";
 
interface MaskInputProps extends StandardProps, FormControlBaseProps<string> {
    mask?: string[];
    regex?: string;
    required?: boolean;
    onChangeEnd?: any;
    password?: boolean | string;
    isEdit?: boolean;
    password_character?: string;
    oldValue?: string;
    defaultValue?: string;
    placeholder?: string;
    setGlobalState?: Function;
    setValue?: Function;
    setState?: Function;
    setProperties?: Function;
    autoFocus?: boolean;
    onBlur?: Function;
    // componentClass?: React.ElementType;
    // type?: string;
    // id?: string;
    // disabled?: boolean;
    // inputRef?: React.Ref<any>;
    // onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export class MaskInput extends React.Component<any, {}> {
    public maskSelector = new mask.default("99");

    public activeMask: string = "";

    public hasValidation = this.props.mask || this.props.regex || this.props.required ? true : false;
    public firstRun = false;

    public state: any = {
        value: this.props.value,
        isValid: true,
        isDirty: false,
        showPassword: false,
    };
    public lastDefaultValue = this.props.value;
    public inputRef: any;
    constructor(props: MaskInputProps) {
        super(props);
        this.inputRef = React.createRef();
        this.setState({ value: this.props.value });
    }

    shouldComponentUpdate = (props: any, state: any) => {
        if (this.props.value !== props.value && this.lastDefaultValue !== props.value) {
            this.lastDefaultValue = props.value ?? -1;
            this.setState({ value: props.value });
        }
        if(this.props.mask !== props.mask) {
            this.applyMask(props.mask);
        }
        return true;
    };

    applyMask = (maskObj : any) => {
        let masker = new mask.default({
            mask: maskObj,
            placeholder: "_",
            showMaskOnFocus: false,
            showMaskOnHover: false,
            jitMasking: false,
            insertModeVisual: false,
        });
        try {
            if (this.inputRef.current) {
                if (masker) {
                    masker.mask(this.inputRef.current);
                }
            }
        } catch (e) {}
    }

    componentDidUpdate() {
        if (this.inputRef.current) {
            this.inputRef.current.onblur = (evt: any) => {
                this.onChangeInput(evt.target.value, evt);
            };
        }
    }

    componentDidMount() {
        this.lastDefaultValue = this.props.value;
        // this.shortestMask();
        // //console.log({short: this.activeMask})
        // if(this.props.value != this.state.value) {
        //     this.lastDefaultValue = this.props.value;
        //     this.setState({value:this.props.value});
        // }
        if(this.props.value && typeof this.props.value == 'string' && this.props.value.length > 0) {
            if(this.props.value.includes('T') && this.props.value.includes('Z') && dayjs(this.props.value).isValid() && this.props.value.includes(':') && this.props.value.includes('.')){
                this.setState({value: dayjs(this.props.value).format('DD/MM/YYYY HH:mm:ss')})
            }
        }
        if (this.props.password === "true") {
        } else if (this.props.mask) {
            this.applyMask(this.props.mask);
        }
        if (this.inputRef.current) {
            this.inputRef.current.onblur = (evt: any) => {
                this.onChangeInput(evt.target.value, evt);
            };
        }
        if (this.props.value) {
            this.onChangeInput(this.state.value, null);
        }
    }

    onChange = (value: any) => {
        if (value !== undefined && this.props.allowLowercase === undefined) {
            value = value.toLocaleUpperCase();
        }
        if(this.props.allowLowercase && value) {
            value = value.toLowerCase()
        }

        if ((this.props.mask === undefined || this.props.mask.length === 0) && this.props.maxLength) {
            if (value && value.length > this.props.maxLength) {
                value = value.substr(0, this.props.maxLength);
            }
            
        }
        var savedStart : null | number = null;
        if(this.props.mask === undefined || this.props.mask?.length <= 0) {
            savedStart = this.inputRef.current.selectionStart;
        }
        this.setState({ value },() => {
            if(this.props.mask === undefined || this.props.mask?.length <= 0) {
            this.inputRef.current.selectionStart = savedStart;
            this.inputRef.current.selectionEnd = savedStart;
            }
        });
        //
    };

    shortestMask = (value: string | null = null) => {
        var shortest = "";
        if (value == null) {
            value = this.state.value ?? "";
        }
        if (value) {
            value = value.replace(/[^a-zA-Z0-9 ]/g, "");
        }
        // //console.log("Getting shortest for value",value);
        if (this.props.mask) {
            for (let i = 0; i < this.props.mask?.length; i++) {
                let mask = this.props.mask[i].replace(/[^a-zA-Z0-9 ]/g, "");
                // //(this.state.value.length,mask.length);
                if (value !== null) {
                    if (mask.length === value.length || shortest === "") {
                        shortest = this.props.mask[i];
                    }
                }
            }
        }
        this.activeMask = shortest;
        return shortest;
    };
    public lastParsedValue: any = undefined;
    onChangeInput = (value: string, event: React.SyntheticEvent<HTMLElement, Event> | null) => {
        if (this.state.isDirty === false) {
            this.setState({ isDirty: true });
        }
        var isValid = true;
        var outputValue = value;
        // alert(value);
        try {
            if (this.props.mask) {
                this.shortestMask(value);
                if (this.props.value) {
                    outputValue = mask.default.format(outputValue.toString(), { mask: this.activeMask });
                }
                var masked = mask.default.unmask(outputValue.toString(), { mask: this.activeMask });
                var maskValid = mask.default.isValid(outputValue.toString(), { mask: this.activeMask });
                outputValue = masked;
                if (maskValid === false && isValid === true) {
                    isValid = false;
                }
                // alert(outputValue);
                // return;
            }
            outputValue = outputValue.replace(/[\t]/g,'');      
            if (this.props.regex) {
                try {
                    var regexp = new RegExp(this.props.regex);
                    var regexValid = regexp.test(outputValue);
                    if (regexValid === false && isValid === true) {
                        isValid = false;
                    }
                } catch (e) {
                    console.warn("RegExp inválido = " + this.props.regex);
                }
            }
            if (this.props.required) {
                if (outputValue.length <= 0) {
                    if (isValid === true) {
                        isValid = false;
                    }
                }
            }
        } catch (e) {
            console.warn(e);
        }
        var resultValue = "";
        if (isValid) {
            if (outputValue && outputValue.length) {
                resultValue = outputValue;
            } else {
                resultValue = "";
            }
        }
        if (resultValue !== undefined && this.props.allowLowercase === undefined) {
            resultValue = resultValue.toLocaleUpperCase();
        }
        
        if (this.lastParsedValue !== resultValue) {
            this.lastParsedValue = resultValue;
            this.setState({ isValid: isValid });

            if (this.props.onChangeEnd) {
                this.props.onChangeEnd(resultValue, event);
            }
            if (this.props.onBlur && isValid && event != null) {
                this.props.onBlur(resultValue);
            }
        }
        // }
        // this.setState({value: resultValue});
    };

    renderError = () => {
        return (
            <ErrorMessage show={true} placement={"bottomEnd"}>
                teste
            </ErrorMessage>
        );
    };

    renderValid = () => {
        if (this.props.password === "true") {
            return (
                <InputGroup.Button onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                    <i className={classNames("fas fa-fw", "fa-" + (!this.state.showPassword ? "eye" : "low-vision"))}></i>
                </InputGroup.Button>
            );
        }
        if (!this.hasValidation) return null;
        return (
            <InputGroup.Addon>
                {/* <Icon icon="search" /> */}
                {!this.state.isValid && <i className="fas fa-times"></i>}
                {this.state.isValid && <i className="fas fa-check"></i>}
            </InputGroup.Addon>
        );
    };

    validClassName = () => {
        if (this.state.isDirty) {
            return this.state.isValid ? "is-valid" : "is-invalid";
        }
        return "";
    };

    // shouldComponentUpdate(props: any, state: any) {
    //
    //     if (props.defaultValue != this.state.value) {

    //         this.setState({ value: props.defaultValue });
    //     }
    //     //
    //     return true;
    // }

    render() {
        if (this.props.hidden) {
            return null;
        }

        return (
            <div className={classNames("rs-form-control-wrapper w-100", this.validClassName())}>

                <InputGroup  /*inside*/ disabled={this.props.disabled || this.props.readonly}>

                    {/* <FormControl name="teste" ></FormControl> */}
                    {this.props.preffix && <InputGroup.Addon>{this.props.preffix}</InputGroup.Addon>}
                    <Input
                        autoFocus={this.props.autoFocus}
                        type={this.props.password === "true" && this.state.showPassword === false ? "password" : "text"}
                        inputRef={this.inputRef}
                        name={this.props.name ?? undefined}
                        onChange={this.onChange}
                        disabled={this.props.disabled || this.props.readonly}
                        // onChange={(evt:any) => this.setState({value: evt.target.value})}
                        placeholder={this.props.mask ? this.props.mask[0] : this.props.placeholder}
                        value={this.state.value ?? ''}>                            
                        </Input>

                        {this.props.suffix && <InputGroup.Addon>{this.props.suffix}</InputGroup.Addon>}

                    {(this.state.isDirty === true || this.props.password === "true" || this.props.oldValue) && this.renderValid()}
                    {this.props.maxLength > 0 && this.props.mask === undefined && (
                        <div className="rs-input-meta">
                            {this.state.value ? this.state.value.length : 0}/{this.props.maxLength}
                        </div>
                    )}
                </InputGroup>
                {/* {JSON.stringify(this.props.mask)} */}
                {/* {this.state.value} */}
            </div>
        );
        // if (this.props.isEdit && this.state.value !== undefined) {
        //     return (
        //         <div className={classNames("rs-form-control-wrapper w-100", this.validClassName())}>
        //             <InputGroup inside>
        //                 <Input
        //                     type={this.props.password === "true" && this.state.showPassword === false ? "password" : "text"}
        //                     inputRef={this.inputRef}
        //                     onChange={this.onChangeInput}
        //                     placeholder={this.props.mask ? this.props.mask[0] : this.props.placeholder}
        //                     defaultValue={this.state.value}></Input>
        //                 {this.state.value}
        //                 {(this.state.isDirty === true || this.props.password === "true" || this.props.oldValue) && this.renderValid()}
        //             </InputGroup>
        //         </div>
        //     );
        // }
        // return (
        //     <div className={classNames("rs-form-control-wrapper w-100", this.validClassName())}>
        //         <InputGroup inside>
        //             <Input
        //                 type={this.props.password === "true" && this.state.showPassword === false ? "password" : "text"}
        //                 inputRef={this.inputRef}
        //                 onChange={this.onChangeInput}
        //                 placeholder={this.props.mask ? this.props.mask[0] : this.props.placeholder}
        //                 defaultValue={this.state.value}></Input>
        //             {/* {this.state.value} */}
        //             {(this.state.isDirty === true || this.props.password === "true" || this.props.oldValue) && this.renderValid()}
        //         </InputGroup>
        //     </div>
        // );
    }
}
