import React from "react";
import {
  Panel,
  Placeholder,
  Row,
  Col,
  Button,
  Checkbox,
  Icon,
  IconButton,
  Input,
  Notification,
  PanelGroup,
  Table,
  Loader,
  SelectPicker,
  DatePicker,
} from "rsuite";
import axios from "../../../utilities/axios";
import { ReportTransferRequest } from "../../reports/ReportTransferRequest";
import dayjs from "dayjs";
const { Column, HeaderCell, Cell } = Table;

export class GraphicCustom extends React.Component<any, {}> {
  public state: any = {
    totalNumberRequest: null,
    totalNumberShipping: null,
    totalNumberRequestPending: null,
    totalNumberShippingPending: null,
    loading: false,
    dateIni: dayjs().startOf('month').format('YYYY-MM-DD 00:00:00.000'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DD 23:59:59.999'),
    loadingSearch: false,
    showPlaceholder: false
  };

  Card = (props: any) => (
      <Panel 
          {...props} 
            bordered 
            header={<div style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%", whiteSpace: "nowrap", justifyContent: "center" }}>{props.title}</div>}
            style={{ borderRadius: 10, height: 180 }}
      >
        {this.state.showPlaceholder === true || !props.value ? (
          <Placeholder.Paragraph />
        ) : (
          <div style={{ display: "flex", marginTop: 20, alignItems: "center", justifyContent: "left"}}>
            <h4>Quantidade: {props.value}</h4>
          </div>
        )}
      </Panel>
  );

  totalNumberRequest = async () => {
    await axios
      .get(`api/v1/dashboard/requests`, {
        params: {
          dateIni: this.state.dateIni,
          endDate: this.state.endDate
        }
      })
      .then((res) => {
        this.setState({ totalNumberRequest: res.data.data });
      })
      .catch((e) => console.log(e.message));
  };

  totalNumberShipping = async () => {
    await axios
      .get(`api/v1/dashboard/shipping`, {
        params: {
          dateIni: this.state.dateIni,
          endDate: this.state.endDate
        }
      })
      .then((res) => {
        this.setState({ totalNumberShipping: res.data.data });
      })
      .catch((e) => console.log(e.message));
  };

  totalNumberRequestPending = async () => {
    await axios
      .get(`api/v1/dashboard/requests/pending`, {
        params: {
          dateIni: this.state.dateIni,
          endDate: this.state.endDate
        }
      })
      .then((res) => {
        this.setState({ totalNumberRequestPending: res.data.data });
      })
      .catch((e) => console.log(e.message));
  };

  totalNumberShippingPending = async () => {
    await axios
      .get(`api/v1/dashboard/shipping/pending`, {
        params: {
          dateIni: this.state.dateIni,
          endDate: this.state.endDate
        }
      })
      .then((res) => {
        this.setState({ totalNumberShippingPending: res.data.data });
      })
      .catch((e) => console.log(e.message));
  };

  componentDidMount = () => {
    const { dateIni, endDate } = this.state
    if (dateIni && endDate) {
      this.totalNumberRequest()
      this.totalNumberShipping()
      this.totalNumberRequestPending()
      this.totalNumberShippingPending()
    }
  };


  handleClick = () => {
    this.setState({ loading: true })
    this.props.history.push(`/dashboard/configuracoes/transfer_requests/add`)
    this.setState({ loading: false })
  }

  public locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
  };

  handleFilterDashboard = async () => {
    this.setState({ loadingSearch: true, showPlaceholder: true });
    try {
      await Promise.all([ 
        this.totalNumberRequest(),
        this.totalNumberShipping(),
        this.totalNumberRequestPending(),
        this.totalNumberShippingPending()
      ]);
    } catch (error) {
      console.error("Erro ao carregar os dados:", error);
    } finally {
      this.setState({ loadingSearch: false, showPlaceholder: false });
    }
  }

  render() {
    const { totalNumberRequest, totalNumberShipping, totalNumberRequestPending, totalNumberShippingPending } = this.state
    return (
      <>
        <Row style={{margin: "0 0 20px 0"}}>
          <Col md={5} sm={12}>
            <label>Data Inicial</label>
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={this.state.dateIni}
              locale={this.locale}
              format="DD/MM/YYYY"
              onChange={(date: any) => {
                this.setState({ dateIni: dayjs(date).format('YYYY-MM-DD 00:00:00.000') })
              }}
              placeholder="DD/MM/AAAA"
            />
          </Col>
          <Col md={5} sm={12}>
            <label>Data Final</label>
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={this.state.endDate}
              locale={this.locale}
              format="DD/MM/YYYY"
              onChange={(date: any) => {
                this.setState({ endDate: dayjs(date).format('YYYY-MM-DD 23:59:59.999') }) 
              }}
              placeholder="DD/MM/AAAA"
            />
          </Col>
          <Col md={3} sm={12} >
            <Button
              color="green"
              loading={this.state.loadingSearch}
              style={{ borderRadius: 6, width: '100%', height: "100%", fontWeight: "bold", color: "white", fontSize: 14, marginTop: 19 }}
              onClick={() => this.handleFilterDashboard()}
            >Buscar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={5} sm={12}>
            <this.Card title={"Qtd. Total de Solicitações"} value={totalNumberRequest} />
          </Col>
          <Col md={5} sm={12}>
            <this.Card title={"Qtd. Total de Envios"} value={totalNumberShipping} />
          </Col>
          <Col md={5} sm={12}>
            <this.Card title={"Qtd. Total Solic. Pendentes"} value={totalNumberRequestPending} />
          </Col>
          <Col md={5} sm={12}>
            <this.Card title={"Qtd. Total Envios Pendentes"} value={totalNumberShippingPending} />
          </Col>
          <Col md={4} sm={12} style={{ height: "100%"}}>
            <Button
              color="red"
              loading={this.state.loading}
              style={{ borderRadius: 10, width: '100%', height: 180, fontWeight: "bold", color: "white" }}
              onClick={() => this.handleClick()}
            ><i className="fas fa-sync-alt"></i>Atualizar
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
