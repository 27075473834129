import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from "rsuite";

export class UserCpfCnpjEvents extends Event {
    onBlur = (value: any, setField: any, setState: any) => {
        if (value.length === 11) {
            this.setField("user_name", {
                width: '6'
            })
            this.setField("user_fantasy_name", {
                hidden: true,
                required: false
            })
        }
        if (value.length === 14) {
            this.setField("user_fantasy_name", {
                width: '3',
                hidden: false,
                required: true,
                // width: 6
            })
            this.setField("user_name", {
                width: '3',
            })
        }
        this.setState({
            loadings: [
                "user_cpf_cnpj",
                "user_name",
                "user_fantasy_name",
                "user_phone_number",
                "user_email",
                "user_cep",
                "user_address_street",
                "user_address_number",
                "user_address_complement",
                "user_address_neighborhood",
                "user_fk_state_id",
                "user_fk_city_id"
            ]
        });
        axios
            .get("/api/v1/consulta-cnpj/" + value)
            .then(async(res: any) => {
                const { data } = res.data;
                if (data.status === "ERROR") {
                    setState("loadings", []);
                    return;
                }
                if (data) {
                    if (data.fantasia.length) {
                        setField("user_fantasy_name", data.fantasia.length ? data.fantasia : data.nome);
                    }
                    setField("user_name", data.nome);
                    setField("user_email", data.email);
                    setField("user_cep", data.cep.split("-").join(""));
                    setField("user_address_number", data.numero);
                    setField("user_address_complement", data.complemento)

                    setField("user_address_street", data.logradouro);
                    setField("user_address_neighborhood", data.bairro);

                    let stateId: any = undefined;
                    await axios
                        .get("/api/v1/states/select", {
                            params: {
                                state: data.uf,
                            },
                        })
                        .then(async(res) => {
                            const { items } = res.data;
                            const { label, value } = items[0] ?? undefined;
                            stateId = value;
                            if (label && value) {
                                setField("user_fk_state_id", { label, value: value.toString() });

                                await axios
                                    .get("/api/v1/cities/select", {
                                        params: {
                                            search: data.municipio,
                                            state: stateId,
                                        },
                                    })
                                    .then((res) => {
                                        var { items } = res.data;
                                        const labelCity = items[0].label;
                                        const valueCity = items[0].value;
                                        if (labelCity && valueCity) {
                                            setField("user_fk_city_id", { label: labelCity, value: valueCity.toString() });
                                        }
                                    })
                                    .catch((e) => { console.log("valor do erro 2", e) })
                            }
                        })
                }
            })
            .catch((e) => {
                setState("loadings", []);
            })
            .finally(() => {
                setState("loadings", []);
            });
    };
}
