import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col, Whisper, InputGroup, Tooltip, Modal, Placeholder, InputNumber, FormGroup, ControlLabel, HelpBlock } from "rsuite";
import { Event } from "../Event";
import { UserContext } from "../../components/authProvider/main";
import { withRouter } from "react-router";

var userInfoRole: any = '';
var userInfoId: any = '';
class TransferRequestFooterComponent extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;
    public state = {
        show: false,
        showEmail: false,
        loading: false,
        showSituationPsms: false,
        showSituationTypeLiberation: false,
        showSituationRm: false,
        situacaoModal: "",
        started: false,
        loadingAccepted: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false,
        status: null,
        openModal: false,
        nfNumber: null,
        quantities: null,
        acceptedButtonLoading: false,
        openModalRefuse: false,
        openModalCancelClient: false,
        showDonateClient: false,
        showAcceptOrCancel: false
    };

    handleOpen = () => {
        this.setState({
            openModal: true
        })
    }

    handleClose = () => {
        this.setState({
            openModal: false
        })
    }

    // axios.get("/api/v1/getVuupt", this.props.values.formValues.transfer_request_id)
    // .then(response => {
    //     console.log("contextType", response.data);
    // })
    // .catch(error => {
    //     console.error("Erro ao fazer a requisição:", error);
    // });


    cancelTransfer = () => {
        this.setState({ loading: true })
        if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_observations)) {
            if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id)) {

                this.props.onSubmit(
                    () => {
                        axios
                            .post(`/api/v1/transfer-request`, { id: this.props.values.formValues.transfer_request_id })
                            .then((res) => {
                                if (res.status == 200) {
                                    Notification.success({
                                        title: "Sucesso!",
                                        description: 'Transferência cancelada com sucesso!'
                                    })

                                    this.setState({ loading: false })
                                }
                            }).catch((e) => {
                                console.log("Erro ao consultar o status: ", e)
                            }).finally(() => {
                                this.setState({ loading: false })
                            })
                    },
                    () => {
                        this.setState({ loading: false });
                    })
            } else {
                Notification.warning({
                    title: "Atenção!",
                    description: 'Divergência com a situação, contate um Administrador',
                    duration: 2500
                });
                this.setState({ loading: false })
            }
        } else {
            Notification.warning({
                title: "Atenção!",
                description: 'Por favor preencher o motivo da Recusa!',
                duration: 2500
            });
            this.setState({ loading: false })
        }
    }


    cancelTransferRequest = () => {
        this.setState({ loading: true });
        if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_observations)) {
            if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id)) {
                this.props.onSubmit(
                    () => {
                        axios
                            .post(`/api/v1/order-refuse-request`, { id: this.props.values.formValues.transfer_request_id })
                            .then((res) => {
                                if (res.status == 200) {
                                    Notification.success({
                                        title: "Sucesso!",
                                        description: 'Transferência cancelada com sucesso!'
                                    });
                                    this.setState({ loading: false })
                                }
                            }).catch((e) => {
                                console.log("Erro ao consultar o status: ", e)
                            }).finally(() => {
                                this.setState({ loading: false })
                            })
                    })
            } else {
                Notification.warning({
                    title: "Atenção!",
                    description: 'Divergência com a situação, contate um Administrador!',
                    duration: 2500
                });
                this.setState({ loading: false })
            }
        } else {
            Notification.warning({
                title: "Atenção!",
                description: 'Por favor preencher o motivo do Cancelamento',
                duration: 2500
            });
            this.setState({ loading: false })
        }
    }


    acceptedOrder = async () => {
        this.setState({ loadingAccepted: true })
        const valuesForm = this.props.values.formValues
        if (!['', undefined, null].some(value => [valuesForm.transfer_request_nf_number, valuesForm.transfer_request_serie, valuesForm.transfer_request_access_key, valuesForm.transfer_request_nf_value, valuesForm.transfer_request_quantity_volumes].includes(value))) {
            this.props.onSubmit(
                () => {
                    axios
                        .post(`/api/v1/order-accepted`, { id: this.props.values.formValues.transfer_request_id, nf: this.props.values.formValues.transfer_request_nf_number })
                        .then((res) => {
                            if (res.status === 200) {
                                Notification.success({
                                    title: "Sucesso!",
                                    description: 'Pedido aceito!'
                                });
                            }
                            return;
                        }).catch((e) => {
                            Notification.error({
                                title: "Erro!",
                                description: 'Não foi possível aceitar o pedido',
                                duration: 2500
                            });
                            this.setState({ loadingAccepted: false })
                            return;
                        })
                        .finally(() => {
                            this.setState({ loadingAccepted: false })
                        })
                })
        } else {
            Notification.error({
                title: "Atenção!",
                description: 'Verificar os campos de NF, Série, Chave de Acesso ou Quantidade de volumes!',
                duration: 2500
            });
            this.setState({ loadingAccepted: false })
        }
    }


    getInfosVuuptRequest = async () => {
        this.setState({ loading: true })
        await axios.get("/api/v1/getVuuptRequest/", this.props.values.formValues.transfer_request_id)
            .then(response => {
                const valueData = response.data
                // console.log("contextType 1", valueData);
                window.open("https://app.vuupt.com/manager/orders/" + valueData.data[0].transfer_request_vuupt_delivery_id, '_blank');

            })
            .catch(error => {
                console.error("Erro ao fazer a requisição:", error);
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    getInfosVuuptCollect = async () => {
        this.setState({ loading: true })

        await axios.get("/api/v1/getVuuptCollect/", {
            params: { transfer_request_id: this.props.values.formValues.transfer_request_id }
        })
            .then(response => {
                const valueData = response.data
                // console.log("contextType 2", valueData);
                window.open("https://app.vuupt.com/manager/orders/" + valueData.data[0].transfer_request_vuupt_collect_id, '_blank');

            })
            .catch(error => {
                console.error("Erro na requisição:", error);
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    createServiceVuupt = async () => {
        const { nfNumber, quantities } = this.state;
        this.setState({ acceptedButtonLoading: true })
        if (nfNumber === null) {
            Notification.warning({
                title: "Aviso!",
                description: 'Por favor, informe o número da NF!',
                duration: 2500
            });
            this.setState({ acceptedButtonLoading: false })
        } else if (quantities === null) {
            Notification.warning({
                title: "Aviso!",
                description: 'Por favor, informe a quantidade de volumes!',
                duration: 2500
            });
            this.setState({ acceptedButtonLoading: false })
        } else {
            await axios.post('/api/v1/create-service', { idTransferRequest: this.props.values.formValues.transfer_request_id, nf: nfNumber, quantities: quantities })
                .then((res) => {
                    if (res.data.status == 200 || res.data.status == 201) {
                        Notification.success({
                            title: "Sucesso!",
                            description: 'Pedido aceito com sucesso!',
                        });
                        this.handleClose()
                        setTimeout(() => {
                            this.props.history.push("/dashboard/configuracoes/transfer_requests")
                        }, 2000)
                    } else {
                        Notification.error({
                            title: "Erro!",
                            description: 'Falha ao aceitar a transfêrencia!',
                            duration: 2500
                        });
                    }
                })
                .catch((e) => {
                    console.log("Erro na requisição: ", e)
                })
                .finally(() => this.setState({ acceptedButtonLoading: false }))
        }
    }

    render() {
        return (<>
            {/* {console.log("this.props", this.props.values.formValues.transfer_request_fk_user_id)} */}
            <Button loading={this.state.loading} onClick={() => this.props.onSubmit()} color={"green"} className="ml-4">
                <i className="fas fa-save fa-fw mr-2"></i>
                Gravar
            </Button>

            {([1].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id) && [this.context.data.user_id].includes(this.props.values.formValues.transfer_request_collect_fk_user_id))
                && (
                    <>
                        <Button loading={this.state.loadingAccepted} onClick={() => {
                            this.acceptedOrder();
                        }} color="blue" className="ml-2">
                            <i className="fas fa-window-close fa-fw mr-2"></i>
                            Aceitar Transferencia
                        </Button>
                        <Button loading={this.state.loading}
                            onClick={() => {
                                this.cancelTransfer();
                            }}
                            // onClick={this.cancelTransferRequest} 
                            color="red" className="ml-4">
                            <i className="fas fa-window-close fa-fw mr-2"></i>
                            Recusar Transferencia
                        </Button>
                    </>
                )}
            {([1].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id) && [this.context.data.user_id].includes(this.props.values.formValues.transfer_request_fk_user_id))
                && (
                    <Button loading={this.state.loading}
                        onClick={() => {
                            this.cancelTransferRequest();
                        }}
                        // onClick={this.cancelTransferRequest} 
                        color="red" className="ml-4">
                        <i className="fas fa-window-close fa-fw mr-2"></i>
                        Cancelar Solicitação
                    </Button>
                )
            }

            {/* solicitante */}
            {([10].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id) && [this.context.data.user_id].includes(this.props.values.formValues.transfer_request_fk_user_id))
                && (
                    <Button loading={this.state.loading}
                        onClick={() => {
                            this.getInfosVuuptRequest();
                        }}
                        // onClick={this.cancelTransferRequest} 
                        color="blue" className="ml-4">
                        <i className="fas fa-location-arrow  fa-fw mr-2"></i>
                        Solicitação Vuupt
                    </Button>
                )
            }

            {/* coleta */}
            {([10].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id) && [this.context.data.user_id].includes(this.props.values.formValues.transfer_request_collect_fk_user_id))
                && (

                    <Button loading={this.state.loading}
                        onClick={() => {
                            this.getInfosVuuptCollect();
                        }}
                        // onClick={this.cancelTransferRequest} 
                        color="blue" className="ml-4">
                        <i className="fas fa-location-arrow  fa-fw mr-2"></i>
                        Solicitação Vuupt
                    </Button>
                )
            }
        </>)
    }

}

class TransferRequestFooterAddComponent extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;
    public state = {
        loading: false
    }

    render(): React.ReactNode {
        return (
            <>
                {this.context?.data?.user_blocked == true ? (<>
                </>)
                    : (
                        <Button loading={this.state.loading} onClick={() => this.props.onSubmit()} color={"green"} className="ml-4">
                            <i className="fas fa-save fa-fw mr-2"></i>
                            Gravar
                        </Button>
                    )}
            </>
        )
    }
}



const TransferRequestFooterWithRouter = withRouter(TransferRequestFooterComponent);
const TransferRequestFooterAddWithRouter = withRouter(TransferRequestFooterAddComponent)

export class TransferRequestFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <TransferRequestFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };

    public onRenderAddFooter = (onSubmit: any) => {
        return (
            <>
                <TransferRequestFooterAddWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}
