import axios from "axios";
import { Event } from "../Event";

export class UserEvents extends Event {
  public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
    if (this.globalProps.match.url.includes('/add')) {
      this.setFieldValue("user_name", "‎");
      this.setFieldValue("user_password", "999");
    }

    if (!['0', 0, undefined, null].includes(this.globalProps.values.user_fk_role_id)) {
      this.setField('user_fk_role_id', {
        readonly: true
      })
    }
    if (!this.globalProps.match.url.includes('/add') && this.globalProps.values.user_fk_role_id === 17) {
      // const fieldsToEnabled: any = ['user_fk_project_id', 'user_cdl', 'user_regional', 'user_cost_center', 'user_cost_center_name', 'user_restricts'];
      // for (const i in fieldsToEnabled) {
      //   this.setField(fieldsToEnabled[i], {
      //     hidden: false,
      //     required: true
      //   })

      this.setField("user_fk_role_id", {
        width: '3'
      })
      this.setField("user_cost_center", {
        width: '3'
      })
      this.setField("user_cost_center_name", {
        width: '3'
      })
      this.setField("user_restricts", {
        width: "6",
        hidden:false,
      })

      if (this?.globalProps?.values?.user_cpf_cnpj.length == 14) {
        this.setField("user_name", {
          width: '3'
        })
        this.setField("user_fantasy_name", {
          width:'3',
          hidden:false,
        })
      }

      this.setField("user_cpf_cnpj", {
        width: '2'
      })
      this.setField("user_cep", {
        width: '2'
      })
      this.setField("user_address_complement", {
        width: '4'
      })
      this.setField("user_address_reference", {
        width: '4'
      })


      const fieldsToEnabled: any = ['user_fk_project_id', 'user_cdl', 'user_regional', 'user_cost_center', 'user_cost_center_name', 'user_blocked', 'user_active', 'user_cpf_cnpj', 'user_password', 'user_name', 'user_fantasy_name', 'user_cep', 'user_address_street', 'user_address_neighborhood',
        'user_address_number', 'user_fk_state_id', 'user_fk_city_id', 'user_address_complement', 'user_address_reference', 'user_email', 'user_cod_vuupt', 'user_first_contact_name',
        'user_email_first_contact', 'user_second_contact_name', 'user_email_second_contact', 'user_general_observations', 'user_attachments'];
      this.setState("loadings", fieldsToEnabled)
      for (const i in fieldsToEnabled) {
        this.setField(fieldsToEnabled[i], {
          readonly: false,
          hidden: false,
        })
      }
      setTimeout(() => {
        this.setState({ loadings: [] });
      }, 2000);
    } else {


      const fieldsToEnabled: any = ['user_blocked', 'user_active', 'user_cpf_cnpj', 'user_password', 'user_name', 'user_fantasy_name', 'user_cep', 'user_address_street', 'user_address_neighborhood',
        'user_address_number', 'user_fk_state_id', 'user_fk_city_id', 'user_address_complement', 'user_address_reference', 'user_email', 'user_cod_vuupt', 'user_first_contact_name',
        'user_email_first_contact', 'user_second_contact_name', 'user_email_second_contact', 'user_general_observations', 'user_attachments'
      ];
      this.setState("loadings", fieldsToEnabled)
      for (const i in fieldsToEnabled) {
        this.setField(fieldsToEnabled[i], {
          readonly: false,
        })
      }
      setTimeout(() => {
        this.setState({ loadings: [] });
      }, 2000);
    }

    // if (this.globalProps.match.url.includes('/add')) {
    //   this.setFieldValue("user_name", {
    //     value: "aaa"
    //   });
    // }
  }
}
export class UserCepEvents extends Event {
  onBlur = (value: any, setField: any, setState: any) => {
    if (value.length === 8) {
      setState("loadings", [
        "user_address_street",
        "user_address_neighborhood",
        "user_fk_state_id",
        "user_fk_city_id"
      ]);

      axios
        .get("https://viacep.com.br/ws/" + value + "/json/")
        .then((res: any) => {
          // console.log("consulta cep", res.data);

          if (res.data) {
            setField("user_address_street", res.data.rua);
            setField("user_address_neighborhood", res.data.bairro);

            console.log("Valor do res api cep", res.data)

            var stateId: any = undefined;
            axios
              .get("/api/v1/states/select", {
                params: {
                  state: res.data.uf
                },
              })
              .then((res: any) => {
                const { items } = res.data;
                console.log("items states", items)
                const { label, value } = items[0] ?? undefined;
                stateId = value;
                if (label && value) {
                  setField("user_fk_state_id", { label, value: value.toString() });
                }
              })
              .catch((e) => { console.log("Valor do erro", e) })
              .finally(() => {
                // if (this.globalProps.match.url.includes('/add')) {
                axios
                  .get("/api/v1/cities/select", {
                    params: {
                      state: stateId,
                      city_ibge_code: res.data.ibge
                    },
                  })
                  .then((res) => {
                    var { items } = res.data;
                    console.log("items cities", items)
                    var { label, value } = items[0] ?? undefined;
                    // console.log("label e values", label, value);
                    if (label && value) {
                      this.setFieldValue("user_fk_city_id", value);
                    }
                  })
                  .catch((e) => { })
                  .finally(() => {
                    setState("loadings", []);
                  });
                // }
              });

            setField("user_address_street", res.data.logradouro);
            setField("user_address_neighborhood", res.data.bairro);
            // setField("user_email", res.data.email);
          }
        })
        .catch((e) => {
          setState("loadings", []);
        })
        .finally(() => {
          setState("loadings", []);
        });
    }
  };
}
export class UserFkRoleIdEvents extends Event {
  public onChange = (value: any, setField: any, setState: any, formValues: any) => {
    if (!['0', 0, undefined, null].includes(value)) {
      this.setField('user_fk_role_id', {
        readonly: true
      })
    }
    if (value == 17) {
      const fieldsToRequired: any = ['user_fk_project_id', 'user_cdl', 'user_regional', 'user_cost_center', 'user_cost_center_name',];
      for (const i in fieldsToRequired) {
        this.setField(fieldsToRequired[i], {
          hidden: false,
          required: true,
          readonly: true
        })
      }
      this.setField('user_restricts', {
        hidden: false,
      })

      const fieldsToEnabled: any = ['user_fk_project_id', 'user_cdl', 'user_regional', 'user_cost_center', 'user_cost_center_name', 'user_blocked', 'user_active', 'user_cpf_cnpj', 'user_password', 'user_name', 'user_fantasy_name', 'user_cep', 'user_address_street', 'user_address_neighborhood',
        'user_address_number', 'user_fk_state_id', 'user_fk_city_id', 'user_address_complement', 'user_address_reference', 'user_email', 'user_cod_vuupt', 'user_first_contact_name',
        'user_email_first_contact', 'user_second_contact_name', 'user_email_second_contact', 'user_general_observations', 'user_attachments'
      ];
      this.setState("loadings", fieldsToEnabled)
      for (const i in fieldsToEnabled) {
        this.setField(fieldsToEnabled[i], {
          readonly: false,
        })
      }

      const fieldsSetWidthThree: any = ['user_fk_role_id', 'user_cost_center', 'user_cost_center_name'];
      for (const i in fieldsSetWidthThree) {
        this.setField(fieldsSetWidthThree[i], {
          width: '3'
        })
      }
      this.setField("user_cpf_cnpj", {
        width: '2'
      })
      this.setField("user_cep", {
        width: '2'
      })
      this.setField("user_address_complement", {
        width: '4'
      })
      this.setField("user_address_reference", {
        width: '4'
      })

      setTimeout(() => {
        this.setState({ loadings: [] });
      }, 2000);
    } else {
      const fieldsToDisabled: any = ['user_fk_project_id', 'user_cdl', 'user_regional', 'user_cost_center', 'user_cost_center_name', 'user_restricts','user_blocked'];
      for (const i in fieldsToDisabled) {
        this.setField(fieldsToDisabled[i], {
          hidden: true,
          // required: false
        })
      }

      const fieldsToEnabled: any = ['user_blocked', 'user_active', 'user_cpf_cnpj', 'user_password', 'user_name', 'user_fantasy_name', 'user_cep', 'user_address_street', 'user_address_neighborhood',
        'user_address_number', 'user_fk_state_id', 'user_fk_city_id', 'user_address_complement', 'user_address_reference', 'user_email', 'user_cod_vuupt', 'user_first_contact_name',
        'user_email_first_contact', 'user_second_contact_name', 'user_email_second_contact', 'user_general_observations', 'user_attachments'
      ];

      this.setState("loadings", fieldsToEnabled)

      for (const i in fieldsToEnabled) {
        this.setField(fieldsToEnabled[i], {
          readonly: false,
        })
      }
      const fieldsSetWidthThree: any = ['user_fk_role_id', 'user_fk_state_id', 'user_fk_city_id'];
      for (const i in fieldsSetWidthThree) {
        this.setField(fieldsSetWidthThree[i], {
          width: '3'
        })
      }
      this.setField("user_cpf_cnpj", {
        width: '3'
      })
      this.setField("user_cep", {
        width: '2'
      })
      this.setField("user_address_complement", {
        width: '6'
      })
      this.setField("user_address_reference", {
        width: '6'
      })
      this.setField("user_password", {
        width: '4'
      })
      setTimeout(() => {
        this.setState({ loadings: [] });
      }, 2000);
    }
  }
}