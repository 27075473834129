import axios from "../../utilities/axios";
import { Event } from "../Event";

export class UserFkProjectIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        console.log('value :::> ', value)
        this.setState('loadings',[
            'user_restricts'
        ])
        this.setFieldValue('user_restricts',[]);
        
        this.setState('loadings',[])
        // await axios.get('/api/v1/users/restrict/select', {
        //     params: {
        //         project_id: value
        //     }
        // })
        //     .then((response) => {
        //         console.log('response :::> ', response.data.items)
        //         this.setFieldProperty('user_restricts','options',response.data.items);
        //     })
        //     .catch((err) => {
        //         console.log('error :::> ', err.response.data)
        //     })
        //     .finally(() => {
        //     })
    }
}