import React from "react";
import { Button, Divider, Notification, Panel } from "rsuite";
import { FileInput } from "../components/formfields/FileInput";
import { FileInputValidator } from "../components/formfields/FileInputValidator";
import axios from "../utilities/axios";

const alertMessage: any = Notification

export class ValidatorPagePedidoxPedido extends React.Component<any, {}> {
    public state: any = {
        currentStep: 1,
        uploader1: [],
        // uploader2: [],
        onProcess: false

    }
    uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    changeInputFile = (value: any, field: any, remove: any) => {
        console.log({ value }, { remove })
        if (value?.length > 0) {
            this.setState({
                [field]: [...value]
            })

        } else {
            if (value?.[0]?.id) {
                this.setState({
                    [field]: [value?.[0]]
                })
            }
        }


        if (remove == true) {
            this.setState({
                [field]: [...value]
            })
        }

    }

    onValidate = async (name: any) => {
        this.setState({ onProcess: true })
        if (name == "uploader1") {
            axios.post("/api/v1/valida-orderxorder", { data: this.state.uploader1 }).then((res) => {
                alertMessage[res.data.type]({ title: res.data.type == "error" ? "Erro!" : "Sucesso!", description: res.data.text })
            }).catch((e) => {

            }).finally(() => {
                this.setState({ onProcess: false })
            })
        }
        if (name == "uploader2") {
            axios.post("/api/v1/valida-orderxxml", { data: this.state.uploader2 }).then((res) => {
                alertMessage[res.data.type]({ title: res.data.type == "error" ? "Erro!" : "Sucesso!", description: res.data.text })
            }).catch((e) => {

            }).finally(() => {
                this.setState({ onProcess: false })
            })
        }

        return

    }


    render(): React.ReactNode {
        return (<>
            <div className="container" style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }}>
                {/* STEP 1 */}
                <Panel bordered className="rs-no-body mb-4 w-100 h-100 h-100-body">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <h4>Pedido x Pedido [ Arquivo Excel ] </h4>
                        <FileInputValidator
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            multiple={false}
                            showQuestions={false}
                            readonly={false}
                            onRemove={(value: any) => this.changeInputFile(value, "uploader1", true)}
                            onChange={(value: any) => this.changeInputFile(value, "uploader1", false)}
                            guid={this.uuidv4()}
                            name={"uploader1"}
                            relation={"validator"}
                        />
                        <Button loading={this.state.onProcess} disabled={this.state.uploader1?.length <= 0} onClick={() => this.onValidate('uploader1')} appearance="primary" color="violet"> <i className="fas fa-fw fa-file-excel"></i> Validar </Button>
                    </div>
                </Panel>

                {/* <Panel bordered className="rs-no-body mb-4 w-100 h-100 h-100-body">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <h4>Pedido x XML [ Arquivo Excel + Arquivos XML ]   <i className="fas fa-fw fa-file-xml"></i></h4>
                        <FileInputValidator
                            accept="text/xml,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            multiple={true}
                            showQuestions={false}
                            readonly={false}
                            onRemove={(value: any) => this.changeInputFile(value, "uploader2", true)}
                            onChange={(value: any) => this.changeInputFile(value, "uploader2", false)}
                            guid={this.uuidv4()}
                            name={"uploader2"}
                            relation={"validator"}
                        />
                        <Button loading={this.state.onProcess} disabled={this.state.uploader2?.length <= 0} onClick={() => this.onValidate('uploader2')} appearance="primary" color="violet"><i className="fas fa-fw fa-file-excel"></i> Validar </Button>
                    </div>
                </Panel> */}
                {JSON.stringify(this.state)}
            </div>
        </>)
    }
}