import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";
import {  Loader } from "rsuite";
import { pagesConfig } from "../../config/pages";
import axios from "../../utilities/axios";

class BreadCrumbsList extends React.Component<any, {}> {
    public state = {
        loading: true,
    };

    public mappings: any = pagesConfig.mappings;

    componentDidUpdate(prevProps: any) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    extractCrudFromRoute(route: string) {
        var spliced = route.substr(1, route.length).split("/");
        return spliced[2];
    }

    componentDidMount() {
        this.onRouteChanged();
    }

    onRouteChanged = () => {
        var crud = this.extractCrudFromRoute(this.props.location.pathname);
        if (crud && !pagesConfig.custom.includes(crud)) {
            axios
                .get("/api/v1/" + crud + "/fields")
                .then((res) => {
                    if (!this.mappings[crud]) {
                        this.mappings[crud] = {
                            name: res.data.fields.$GLOBALS.plural_name,
                            to: "/dashboard/configuracoes/" + crud,
                        };
                    }
                    this.setState({ loading: false });
                    // //
                })
                .catch((e) => {
                    
                })
                .finally(() => {});
        } else {
            this.setState({ loading: false });
        }
        // alert("changed!");
    };

    mapRouteData(routeName: string) {
        var mappings = this.mappings;
        var replacers: any = {};
        var spliced = routeName.substr(1, routeName.length).split("/");
        for (var index in spliced) {
            var splice = spliced[index];
            var map = mappings[splice] ?? undefined;
            if (map && map.key) {
                replacers[map.key] = splice;
            }
        }
        for (var mapIndex in mappings) {
            for (var replaceName in replacers) {
                if (mappings[mapIndex].to) {
                    mappings[mapIndex].to = mappings[mapIndex].to.split(replaceName).join(replacers[replaceName]);
                }
            }
        }
        // //
        let totals = spliced.length - 1;
        var output = spliced.map((item: string, index: any) => {
            var mapped = mappings[item];
            
            if (mapped?.visible === false) {
                return undefined;
            }
            if (!mapped) {
            }
            if (index < spliced.length-1) {
                if (!mapped) {
                    return undefined;
                }
            }
            if( index > 2) {
                return undefined;
            }
            return (
                <div
                    onClick={() => this.props.history.push(mapped?.to)}
                    className={classNames("breadcrumb-item", mapped?.to ? "has-link" : "", totals === index ? "active" : "")}
                    key={index}>
                    {mapped?.name ?? item}
                </div>
            );
            // return <div className="breadcrumb-item" key={index}>{mapped?.name ?? item}</div>
        });

        // //
        return output;
    }

    render() {
        const list = this.mapRouteData(this.props.history.location.pathname);
        // //

        return (
            <div className="breadcrumbs">
                {this.state.loading && <Loader inverse={true} />}
                {!this.state.loading && list}
            </div>
        );
    }
}

export const BreadCrumbs = withRouter(BreadCrumbsList);
