import React from "react";
import { Input, Loader, Notification, Tooltip, Whisper } from "rsuite";
import { ConfirmOverlay } from "../../../confirmOverlay/ConfirmOverlay";
import { Event } from "../../../../events/Event";

export class TransferRequestCustom extends React.Component<any, any> {
  columnItemStyle = {
    flex: "1",
    height: "80px",
    border: "1px solid #ccc"
  };

  title = {
    whiteSpace: "nowrap" as const,
    fontSize: "16px",
    borderBottom: "1px solid #ccc",
    padding: "5px"
  };

  componentDidMount(): void {
    this.updateValues();
  }

  componentDidUpdate(prevProps: any) {
    setTimeout(() => {
      this.updateValues();
    }, 5000)

  }

  updateValues() {
    let qtdItems = 0;
    let total = 0;
    if (this?.props?.values?.item_requests) {
      for (const item of this?.props?.values?.item_requests) {
        qtdItems += parseInt(item.item_request_quantity);
        total += ((item.item_request_total_value) * item.item_request_quantity);
      }
      this.props.values.transfer_request_cost = total ?? 0
      this.props.values.transfer_request_quantity = qtdItems ?? 0
      this.setState({
        qtdItems,
        total
      });
    }
  }

  render() {
    const columnItemStyle = this.columnItemStyle;
    const title = this.title;
    const { qtdItems, total } = this.state || {};

    return (
      <div>
        <div style={{ display: "flex", textAlign: "center", marginTop: "10px" }}>
          <div style={columnItemStyle}>
            <p style={title}>Quantidade de Itens</p>
            <h3>{qtdItems}</h3>
          </div>
          <div style={columnItemStyle}>
            <p style={title}>Valor Total</p>
            <h3>R$ {total?.toFixed(2)}</h3>
          </div>
        </div>
      </div>
    );
  }
}
