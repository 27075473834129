import React from "react";
import { FormGroup, Notification, SelectPicker } from "rsuite";
import axios from "../../../../utilities/axios";
import { Placeholder } from 'rsuite';
import _ from "lodash";
import { UserContext } from "../../../authProvider/main";
const { Paragraph } = Placeholder;


export class TransferRequestCollectUser extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;
    public state = {
        isLoading: false,
        data: [],
        dataValueChange: [],
        loadValues: null,
        valueProjectId: null,
        readonly: false
    }

    componentDidMount(): void {
        try {
            this.setState({ isLoading: true })
            this.onSetValueInitial()
            this.onLoadingData()
            if (this.context.data.user_blocked !== true) {
            }
        } catch (e) {
            console.log('Error :::> ', e)
        }
    }

    onSetValueInitial = () => {
        if (this.props.values.transfer_request_collect_fk_user_id) {
            this.onLoadingData('', this.props.values.transfer_request_collect_fk_user_id)
            this.setState({ loadValues: this.props.values.transfer_request_collect_fk_user_id })
        }
    }

    onLoadingData = (search: string = '', startedValue: string | number | null = null) => {
        axios
            .get('/api/v1/transfer-collect-get/select', {
                params: {
                    search: search,
                    startedValue: startedValue
                }
            })
            .then((response) => {
                if (this.context.data.user_id == this.props.values.transfer_request_collect_fk_user_id) {
                    this.setState({ readonly: response.data.readOnly })
                }
                this.setState({ data: [...this.state.data, ...response.data.items] })
            })
            .catch((err) => {
                Notification.info(
                    {
                        title: 'Info',
                        description: err.response.message
                    }
                )
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    searchBy(keyword: string, label: any, item: any): boolean {
        if(label) {
            const striped = label
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLocaleLowerCase();
            //
            return striped.includes(
                keyword
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLocaleLowerCase(),
            );
        }
        return false
    }

    onSearch = (value: string) => {
        this.onLoadingData(value)
    }

    onChange = (value: any) => {
        this.setState({ loadValues: value })
        this.props.onChange(value)
    }

    render() {
        return (
            <>
                {!this.state.isLoading ? (
                    <FormGroup
                        style={
                            {
                                paddingTop: 4
                            }
                        }
                    >
                        <label>{this.props.label}</label>
                        <div className="rs-form-control-wrapper">
                            <SelectPicker
                                data={this.state.data}
                                onChange={(value) => this.onChange(value)}
                                value={this.state.loadValues}
                                searchBy={this.searchBy}
                                onSearch={(value) => this.onSearch(value)}
                                disabled={this.state.readonly}
                            />
                        </div>
                    </FormGroup>
                ) : (
                    <Paragraph />
                )}
            </>
        )
    }
}