import React from "react";
import { Button, Divider, Input, Modal, Notification, Panel, Table, Tooltip, Whisper, Steps, ButtonGroup, Navbar, Nav } from "rsuite";
import { FileInput } from "../components/formfields/FileInput";
import { FileInputValidator } from "../components/formfields/FileInputValidator";
import { ValidateDuplicated } from "../components/validatoPageXml/ValidateDuplicated";
import { TableRoles } from "../components/validatoPageXml/TableRoles";
import { TableDownload } from "../components/validatoPageXml/TableDownload";
import axios from "../utilities/axios";
import _ from "lodash";
import { TableNewDownloader } from "../components/formfields/custom/TableNewDownloader";

const alertMessage: any = Notification
const { Column, HeaderCell, Cell, Pagination } = Table;

export class ValidatorPagePedidoxXml extends React.Component<any, {}> {
    public state: any = {
        currentStep: 1,
        // uploader1: [],
        uploader2: [],
        onProcess: false,
        xmlData: [],
        objectDataValidate: [],
        values: [],
        valuesValidate: [],
        txtName: '',
        modalData: [],
        newModalData: {},
        showModal: false,
        steps: 0,
        renderSteps: [],
        valuesToDowload: [],
        stepsNav: 0

    }
    uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    onChangeSteps = (step: 'next' | 'previous') => {
        if (step == 'next') {
            this.setState({ steps: this.state.steps + 1 });
        }
        if (step == 'previous') {
            this.setState({ steps: this.state.steps - 1 });
        }
    }

    changeInputFile = (value: any, field: any, remove: any) => {
        // console.log({ value }, { remove })
        if (value?.length > 0) {
            this.setState({
                [field]: [...value]
            })

        } else {
            if (value?.[0]?.id) {
                this.setState({
                    [field]: [value?.[0]]
                })
            }
        }


        if (remove == true) {
            this.setState({
                [field]: [...value]
            })
        }

    }

    onValidate = async (name: any) => {
        this.setState({ onProcess: true })
        if (name === "uploader1") {
            axios.post("/api/v1/valida-orderxorder", { data: this.state.uploader1 }).then((res) => {
                alertMessage[res.data.type]({ title: res.data.type == "error" ? "Erro!" : "Sucesso!", description: res.data.text })
            }).catch((e) => {

            }).finally(() => {
                this.setState({ onProcess: false })
            })
        }
        if (name === "uploader2") {
            axios.post("/api/v1/valida-orderxxml", { data: this.state.uploader2 }).then((res) => {
                alertMessage[res.data.type]({ title: res.data.type == "error" ? "Erro!" : "Sucesso!", description: res.data.text })
                this.setState({ xmlData: res.data.data, values: res.data.data, txtName: res.data.txtName, steps: 1 })
            }).catch((e) => {

            }).finally(() => {
                this.setState({ onProcess: false })
            })
        }
        if (name === 'validateSecondStep') {
            axios.post("/api/v1/validations-roles", { data: this.state.values, txtName: this.state.txtName }).then((res) => {
                alertMessage[res.data.type]({ title: res.data.type == "error" ? "Erro!" : "Sucesso!", description: res.data.text })
                this.setState({ objectDataValidate: res.data.data, valuesValidate: res.data.data, steps: 2 })
            }).catch((e) => {
                console.log('error ::::>', e)
            }).finally(() => {
                this.setState({ onProcess: false })
            })
        }
        if (name === 'generateXmlResponse') {
            axios.post('/api/v1/validate/generate-xml', { data: this.state.valuesValidate })
                .then((res) => {
                    alertMessage[res.data.type]({ title: res.data.type == "error" ? "Erro!" : "Sucesso!", description: res.data.text })
                    this.setState({ valuesToDowload: res.data.data, steps: 3 })
                })
                .catch((e) => {
                    console.log(e)
                    Notification.error({
                        title: 'Error!',
                        description: 'Nenhum XML para gerar retorno!'
                    })
                })
                .finally(() => {
                    this.setState({ onProcess: false })
                })
        }

        return
    }


    renderStepsFunction = () => {
        const output = [];
        if (this.state.steps == 1) {
            return (<>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4>Pedido x XML [ Arquivo Excel + Arquivos XML ]   <i className="fas fa-fw fa-file-xml"></i></h4>
                    <FileInputValidator
                        accept="text/xml,text/txt,.txt"
                        multiple={true}
                        showQuestions={false}
                        readonly={false}
                        onRemove={(value: any) => this.changeInputFile(value, "uploader2", true)}
                        onChange={(value: any) => this.changeInputFile(value, "uploader2", false)}
                        guid={this.uuidv4()}
                        name={"uploader2"}
                        relation={"validator"}
                    />
                    <Button loading={this.state.onProcess} disabled={this.state.uploader2?.length <= 0} onClick={() => this.onValidate('uploader2')} appearance="primary" color="violet"><i className="fas fa-fw fa-file-excel"></i> Validar Primeira Etapa </Button>
                </div>
            </>)
        }
    }


    renderModal = () => {
        let { index, rowData } = this.state.modalData
        const saveModal = () => {
            rowData.nfeProc.NFe[0].infNFe[0].det[0].prod[0].CFOP[0] = this.state.newModalData['cfop'] ?? rowData.nfeProc.NFe[0].infNFe[0].det[0].prod[0].CFOP[0]
            this.state.xmlData[index] = rowData
            this.setState({ xmlData: this.state.xmlData, modalData: [], newModalData: {}, showModal: false })
        }
        return (<>
            <Modal show={this.state.showModal} size="lg" onHide={() => this.setState({ showModal: false, modalData: [] })}>
                <Modal.Header>
                    <b> EDITANDO XML </b>
                </Modal.Header>
                {this.state.showModal && (
                    <Modal.Body>
                        <label>CFOP</label>
                        <Input onChange={(value: any) => this.setState({ ...this.state.newModalData, newModalData: { cfop: value } })} defaultValue={rowData.nfeProc.NFe[0].infNFe[0].det[0].prod[0].CFOP[0]} />
                        {JSON.stringify(this.state.newModalData)}
                    </Modal.Body>)}
                <Modal.Footer>
                    <Button onClick={() => saveModal()} appearance="primary" color="green">Salvar</Button>
                </Modal.Footer>
            </Modal>

        </>)
    }
    onChangeValues = (field: string, value: string | number, index: any) => {
        const updatedValues = [...this.state.values];
        updatedValues[index] = { ...updatedValues[index], [field]: value };
        this.setState({ values: updatedValues });
    }

    onChangeValuesSecondValidate = (field: string, value: string | number, indexProd: any, numberId: any) => {
        const updatedValues = [...this.state.valuesValidate];
        const indexXml = _.findIndex(updatedValues, { 'xml_id': numberId })
        updatedValues[indexXml].prod[indexProd] = { ...updatedValues[indexXml].prod[indexProd], [field]: value };
        this.setState({ values: updatedValues });
    }
    selectTypeNcm = (type: 'selectIsXml' | 'selectIsOrder' | 'selectAlter' | 'cancelNote', firstNameColumn: string, rowData: any, xmlId: number | string) => {
        const updatedValues = [...this.state.valuesValidate];
        for (const i in updatedValues) {
            if (updatedValues[i].xml_id === xmlId) {
                if (['selectIsXml', 'selectIsOrder'].includes(type)) {
                    for (const t in updatedValues[i].prod) {
                        const itemProduct = updatedValues[i].prod[t];
                        if (itemProduct.nItem == rowData.nItem) {
                            if (type === 'selectIsOrder') {
                                itemProduct[`${firstNameColumn}_alter`] = itemProduct[`${firstNameColumn}_validate`]
                            }
                            if (type === 'selectIsXml') {
                                itemProduct[`${firstNameColumn}_alter`] = itemProduct[`${firstNameColumn}_origin`]
                            }
                            if (type === 'selectAlter') {
                                itemProduct[`${firstNameColumn}_alter`] = itemProduct[`${firstNameColumn}_alter`]
                            }
                        }
                    }
                }
                if (type === 'cancelNote') {
                    updatedValues[i].validate = false;
                }
            }
        }
        this.setState({ values: updatedValues });
    }

    selectTab = async (activeTab: string, callback: any = function () { }) => {
        this.setState({ activeTab }, callback);
        if ((this.props.match.params.crud).includes("labs") || (this.props.match.params.crud).includes("layout_")) {
            //delcio            
            var { tabsLoaded } = this.state
            if (this.props.match.params.id) {
                const checkTab = await this.props.onCheckTab(activeTab)
                if (checkTab) {
                    this.setState({ isLoading: true })


                } else {
                    this.setState({ isLoading: false })
                }
                tabsLoaded.push(activeTab)
            }
        }
        // alert("MUDANDO PARA ABA " + JSON.stringify(this.state.tabsLoaded))
    };


    render(): React.ReactNode {
        return (<>
            {this.renderModal()}
            <div className="container" style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }}>
                <Navbar className="col-md-12 pill-tabs">
                    <Navbar.Body>
                        <Nav activeKey={this.state.activeTab}>
                            <Nav.Item onSelect={() => this.setState({ stepsNav: 0})}>
                                Principal
                            </Nav.Item>
                            <Nav.Item onSelect={() => this.setState({ stepsNav: 1})}>
                                XML's Gerados
                            </Nav.Item>
                        </Nav>
                    </Navbar.Body>
                </Navbar>
                <Panel bordered className="rs-no-body mb-4 w-100 h-100 h-100-body">
                    {this.state.stepsNav == 0 && (
                        <>

                            <Steps current={this.state.steps}>
                                <Steps.Item title="Inicio" description="Subindo os XMLS para checar duplicados" />
                                <Steps.Item title="Validações" description="Validando XML / Pedido" />
                                <Steps.Item title="Gerar XML" description="Gera XML de retorno" />
                                <Steps.Item title="XML's de Retorno" description="Para baixar os XML's" />
                            </Steps>
                            <Divider></Divider>
                            {/* {this.state.renderSteps} */}
                            {this.state.steps == 0 && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h4>Pedido x XML [ Arquivo Excel + Arquivos XML ]   <i className="fas fa-fw fa-file-xml"></i></h4>
                                    <FileInputValidator
                                        accept="text/xml,text/txt,.txt"
                                        multiple={true}
                                        showQuestions={false}
                                        readonly={false}
                                        onRemove={(value: any) => this.changeInputFile(value, "uploader2", true)}
                                        onChange={(value: any) => this.changeInputFile(value, "uploader2", false)}
                                        guid={this.uuidv4()}
                                        name={"uploader2"}
                                        relation={"validator"}
                                    />
                                    <Button loading={this.state.onProcess} disabled={this.state.uploader2?.length <= 0} onClick={() => this.onValidate('uploader2')} appearance="primary" color="violet"><i className="fas fa-fw fa-file-excel"></i> Validar Primeira Etapa </Button>
                                </div>
                            )}
                            {this.state.steps == 1 && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <ValidateDuplicated
                                        onLoading={this.state.onProcess}
                                        xmlData={this.state.xmlData}
                                        onChangeFunction={this.onChangeValues}
                                    />
                                    <Button
                                        disabled={this.state.xmlData?.length <= 0}
                                        onClick={() => this.onValidate('validateSecondStep')}
                                        appearance="primary"
                                    >
                                        Validar Segunda Etapa
                                    </Button>
                                </div>
                            )}
                            {this.state.steps == 2 && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    {/* <TableRoles
                                        onLoading={this.state.onProcess}
                                        xmlData={this.state.objectDataValidate}
                                        onChangeFunction={this.onChangeValuesSecondValidate}
                                        onSelectNcm={this.selectTypeNcm}
                                    /> */}
                                    <Button
                                        disabled={this.state.objectDataValidate?.length <= 0}
                                        onClick={() => this.onValidate('generateXmlResponse')}
                                        appearance="primary"
                                        color="orange"
                                    >
                                        Gerar XML's de Retorno
                                    </Button>
                                </div>
                            )}
                            {this.state.steps === 3 && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <TableDownload
                                        data={this.state.valuesToDowload}
                                    />
                                    {/* <Button onClick={() => this.onChangeSteps('previous')} color="orange">
                                    Download de todos arquivos
                                </Button> */}
                                </div>
                            )}
                            <ButtonGroup style={{ marginTop: '15px' }}>
                                <Button onClick={() => this.onChangeSteps('previous')} color="red" disabled={this.state.steps === 0}>
                                    Anterior
                                </Button>
                                <Button style={{ marginLeft: '5px' }} onClick={() => this.onChangeSteps('next')} color="violet" disabled={this.state.steps === 2}>
                                    Próximo
                                </Button>
                            </ButtonGroup>
                            {/* <div style={{ display: "flex", flexDirection: "column" }}>
                            <TableDownload
                                data={this.state.valuesToDowload}
                            />
                        </div> */}
                        </>
                    )}
                    {this.state.stepsNav == 1 && (<>
                        <TableNewDownloader/>
                    </>)}
                </Panel>
            </div>
        </>)
    }
}